import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

import { Autocomplete, Card, TextField, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { getSensorType } from 'src/services/localizationService';
import { useChart } from '../../../components/chart';

SessionChartTab.propTypes = {
  chart: PropTypes.object,
  charts: PropTypes.array,
  events: PropTypes.array,
  currentTime: PropTypes.object,
  sensorTypes: PropTypes.array,
  tabindex: PropTypes.number,
  onChartClick: PropTypes.func,
  eventSensor: PropTypes.string,
  setSensor: PropTypes.func,
};

export default function SessionChartTab({ chart, charts, events, currentTime, onChartClick, eventSensor, setSensor }) {
  const [displayChart, setDisplayChart] = useState(chart);
  const [secondaryChart, setSecondary] = useState(null);

  useEffect(() => {
    if (eventSensor) {
      const selectedChart = charts.find((chart) => chart.data[0].name === `null:${eventSensor}`);
      setDisplayChart(selectedChart);
    }
  }, [eventSensor, charts]);

  function createCurrentTimeAnnotation(currentTime) {
    return {
      x: new Date(currentTime).getTime(),
      borderColor: '#775DD0',
      label: {
        borderColor: '#775DD0',
        style: {
          color: '#fff',
          background: '#775DD0',
        },
        text: 'Current Time',
      },
    };
  }

  function createAnnotation(startTime, label, endTime) {
    return {
      x: new Date(startTime).getTime(),
      x2: new Date(endTime).getTime(),
      label: {
        text: label,
      },
      fillColor: '#d50100',
    };
  }

  function formatName(val) {
    return getSensorType(val.split(':').at(-1));
  }

  function getChart() {
    if (!displayChart?.data) return null;

    const annotations = events.map((event) => createAnnotation(event.startTime, event.label, event.endTime));
    annotations.push(createCurrentTimeAnnotation(currentTime));

    const primarySeries = displayChart?.data[0] || {};
    const secondarySeries = secondaryChart?.data[0] || null;

    const data = [primarySeries, ...(secondarySeries ? [secondarySeries] : [])].filter(
      (series) => series && Array.isArray(series.data) && series.data.length > 0
    );

    if (secondarySeries && primarySeries.data.length !== secondarySeries.data.length) {
      primarySeries.data = primarySeries.data.slice(0, secondarySeries.data.length);
      secondarySeries.data = secondarySeries.data.slice(0, primarySeries.data.length);
    }

    if (data.length === 0) {
      return (
        <Typography sx={{ height: 300 }} variant="h6" align="center">
          No valid data to display
        </Typography>
      );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const chartOptions = useChart({
      plotOptions: { bar: { columnWidth: '16%' } },
      fill: { type: displayChart.data.map((i) => i.fill) },
      labels: displayChart.labels || [],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      annotations: {
        xaxis: [...annotations],
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            pan: true,
            zoom: true,
          },
        },
        events: {
          markerClick: (_, chartContext, opts) => {
            const index = opts.dataPointIndex;
            if (displayChart.labels && index >= 0) {
              onChartClick({ startTime: displayChart.labels[index] });
            }
          },
        },
      },
      tooltip: {
        custom: ({ series, dataPointIndex, w }) => {
          const data = [
            {
              title: formatName(w.globals.seriesNames[0]),
              value: series[0]?.[dataPointIndex] ?? 'N/A',
              color: w.globals.colors[0],
            },
            ...(secondaryChart
              ? [
                  {
                    title: formatName(w.globals.seriesNames[1]),
                    value: series[1]?.[dataPointIndex] ?? 'N/A',
                    color: w.globals.colors[1],
                  },
                ]
              : []),
          ];

          return `
        <div style="padding: 8px; border-radius: 9px;">
          ${data
            .map(
              ({ title, value, color }) => `
              <div style="display: flex; align-items: center;">
                <span style="background-color: ${color}; border-radius: 50%; width: 12px; height: 12px; margin-right: 8px;"></span>
                <span>${title}:&nbsp;&nbsp;<strong>${value}</strong></span>
              </div>
            `
            )
            .join('')}
        </div>
      `;
        },
      },
      ...(secondaryChart && {
        yaxis: [
          {
            title: {
              text: formatName(primarySeries.name),
            },
          },
          {
            opposite: true,
            title: {
              text: formatName(secondarySeries?.name || ''),
            },
          },
        ],
        legend: {
          position: 'bottom',
          formatter: formatName,
        },
      }),
    });

    return (
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={data} options={chartOptions} height={364} />
      </Box>
    );
  }

  const chartNames = charts.map((chart) => chart.name);

  return (
    <Card>
      <Box sx={{ p: 3, pb: 0, display: 'flex', gap: 2 }}>
        <Autocomplete
          disablePortal
          id="combo-box-sensortype"
          options={chartNames}
          defaultValue={chartNames[0]}
          sx={{ width: 300 }}
          onChange={(_, newValue) => {
            const selectedChart = charts.find((chart) => chart.name === newValue);
            setDisplayChart(selectedChart);
            setSensor(null);
          }}
          value={displayChart?.name || ''}
          renderInput={(params) => <TextField {...params} label="Sensor Type" />}
          disableClearable
        />
        <Autocomplete
          disablePortal
          id="combo-box-secondary-sensortype"
          options={chartNames}
          defaultValue={null}
          sx={{ width: 300 }}
          onChange={(_, newValue) => {
            const selectedChart = charts.find((chart) => chart.name === newValue);
            setSecondary(selectedChart);
            setSensor(null);
          }}
          value={secondaryChart?.name || null}
          renderInput={(params) => <TextField {...params} label="Secondary Sensor" />}
          disableClearable
        />
      </Box>
      {displayChart ? (
        getChart()
      ) : (
        <Typography sx={{ height: 300 }} variant="h6" align="center">
          No charts to display
        </Typography>
      )}
      <br />
    </Card>
  );
}
